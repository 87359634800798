<template>
  <div class="boothManagePage">
    <loading v-if="loading"></loading>
    <div v-show="!loading">
      <div class="previewBooth tr">
        <div style="margin-right: 50px" class="showOrhide" v-if="inspectButton('mem:bom:mdi')">
          <el-switch
          
            @change="change"
            style="display: block"
            v-model="value2"
            :active-text="$t('openBooth')"
            active-color="#027FFF"
            inactive-color="#ff4949"
          >
          </el-switch>
          <el-tooltip
            class="item"
            effect="light"
            :content="$t('openTips')"
            placement="bottom"
          >
            <i class="el-icon-question"></i>
          </el-tooltip>
        </div>
        <el-button type="primary" icon="el-icon-view" size="small" @click="goToPreview"
          >{{$t('PreviewBooth')}}</el-button
        >
      </div>
      <div
        class="boothItem"
        v-for="(item, index) in componentsList"
        :key="index"
      >
        <components
          :is="item"
          @initSuccess="initSuccess"
          :booth_id="boothId"
        ></components>
      </div>
    </div>
  </div>
</template>

<script>
import companyInfoModel from "~/baseComponents/baseBoothSet/companyInfoModel";
import seatsModel from "~/baseComponents/baseBoothSet/seatsModel";
import liveModel from "~/baseComponents/baseBoothSet/liveModel";
import videoModel from "~/baseComponents/baseBoothSet/videoModel";
import imageModel from "~/baseComponents/baseBoothSet/imageModel";
import meansModel from "~/baseComponents/baseBoothSet/meansModel";
import loading from "~/baseComponents/loading/loading1";
export default {
  components: {
    loading,
    companyInfoModel,
    seatsModel,
    liveModel,
    videoModel,
    imageModel,
    meansModel,
  },
  data() {
    return {
      loading: true,
      value2: true,
      boothId: "",
      componentsList: [
        "companyInfoModel",
        "seatsModel",
        "liveModel",
        "videoModel",
        "imageModel",
        "meansModel",
      ],
      completeCount: 0,
    };
  },
  mounted() {
    this.getBoothId();
  },
  methods: {
    async change(e) {
      console.log(e);
      let data = await this.$store.dispatch("baseConsole/boothIsSHow", {
        booth_id: this.boothId,
        is_show: e ? 1 : 0,
      });
      if(e){
        this.$message({
          message: this.$t('openBooth'),
          type: 'success'
        });
      }else{
        this.$message.error(this.$t('closeBooth'));
      }
    },
    initSuccess() {
      this.completeCount++;
      if(this.completeCount == this.componentsList.length){
        this.loading = false;
      }
    },
    async getBoothId() {
      let params = {
        meeting_id: this.MEETING_ID,
        company_id: this.USER_INFO.company_id,
      };
      try {
        let res = await this.$store.dispatch(
          "baseConsole/getVistCompanyBoothId",
          params
        );
        if (res.success) {
          this.boothId = res.meeting_booth_id;
          this.value2 = res.is_show ? true : false;
        }
      } catch (e) {}
    },
    goToPreview(){
     
      this.$router.push({
        path:'/boothDetail',
        query:{
          company_id:this.USER_INFO.company_id,
          booth_id:this.boothId,
          isPreview:true,
        }
      })
    }
  },
};
</script>
<style scoped lang="less">
.boothManagePage {
  width: 100%;
  height: 100%;
  background: #f2f2f2;
  .previewBooth {
    margin-bottom: 12px;
    padding-top: 2px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .boothItem {
    margin-bottom: 12px;
  }
}
.showOrhide {
  display: flex;
  align-items: center;
  .el-icon-question {
    font-size: 16px;
    margin-left: 10px;
  }
}
</style>
