import { render, staticRenderFns } from "./imageModel.vue?vue&type=template&id=2824fc16&scoped=true&"
import script from "./imageModel.vue?vue&type=script&lang=js&"
export * from "./imageModel.vue?vue&type=script&lang=js&"
import style0 from "./imageModel.vue?vue&type=style&index=0&id=2824fc16&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../opt/deploy_node_modules/ailaworld/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2824fc16",
  null
  
)

export default component.exports